import { useRef, useEffect, createRef, SetStateAction, Dispatch } from 'react'

import { SubMenuStatusProps } from '../type'

export const useClickOutsideSubMenu = (
  itemsNumber: number,
  subMenuStatus: SubMenuStatusProps,
  setSubMenu: Dispatch<SetStateAction<SubMenuStatusProps>>,
) => {
  const initialRefs = []
  for (let i = 0; i < itemsNumber; i++) {
    initialRefs.push(createRef<HTMLDivElement>())
  }
  const subMenuRefs = useRef(initialRefs)

  useEffect(() => {
    // when SubMenu is open, click outside SubMenu will close the SubMenu
    function handleClickOutside(event: Event) {
      if (subMenuStatus.isSubMenuOpen) {
        const target = event.target as HTMLElement
        let isClickMenu = false
        subMenuRefs.current.forEach((_el, index) => {
          if (subMenuRefs.current[index].current?.contains(target)) {
            isClickMenu = true
          }
        })
        const isSubMenuContained =
          subMenuStatus.currentIndex !== null && isClickMenu

        if (subMenuStatus.isSubMenuOpen && !isSubMenuContained) {
          setSubMenu({
            ...subMenuStatus,
            isSubMenuOpen: false,
          })
        }
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [subMenuStatus])

  return { subMenuRefs }
}
