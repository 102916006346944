import { useState } from 'react'
import { SubMenuStatusProps } from '../type'

export const useSubMenu = () => {
  const [subMenuStatus, setSubMenu] = useState<SubMenuStatusProps>({
    isSubMenuOpen: false,
    currentIndex: null,
  })

  const onClickMenuItem = (currentIndex: number | null) => {
    if (subMenuStatus.currentIndex === null) {
      // first click on a Nav-menu-item
      return setSubMenu({
        isSubMenuOpen: true,
        currentIndex: currentIndex,
      })
    }
    if (subMenuStatus.currentIndex === currentIndex) {
      // click again on same Nav-menu-item
      return setSubMenu({
        isSubMenuOpen: !subMenuStatus.isSubMenuOpen,
        currentIndex: currentIndex,
      })
    }
    if (subMenuStatus.currentIndex !== currentIndex) {
      // click on another Nav-menu-item
      return setSubMenu({
        isSubMenuOpen: true,
        currentIndex: currentIndex,
      })
    }
  }

  const onCloseSubMenu = () =>
    setSubMenu({
      ...subMenuStatus,
      isSubMenuOpen: false,
    })

  return { subMenuStatus, setSubMenu, onClickMenuItem, onCloseSubMenu }
}
