import { FC } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { SearchBar } from '@scm/ui-component-search-bar'
import { Bem } from '@scm/ui-core'
import { SubMenu } from '@scm/ui-module-sub-menu'

import { useSearchOpen, useSubMenu, useClickOutsideSubMenu } from '../hooks'
import { MainMenuItem } from '../type'
import { FullScreenBackground } from './fullScreenBackground'

export const mainMenu = Bem('MainMenu')

export interface Image {
  url: string
  altText?: string
}

export interface SectionLink {
  title: string
  url: string
  openInNewTab?: boolean
  image?: Image
}

export interface Section {
  title: string
  links: SectionLink[]
}

export interface MenuItem {
  title: string
  type?: string
  sections?: Section[]
}

export interface MainMenuProps {
  menuItems: MainMenuItem[]
  isOverOffset: boolean
  openMegaMenu: () => void
}

export const MainMenu: FC<MainMenuProps> = ({
  menuItems,
  isOverOffset,
  openMegaMenu,
}) => {
  const [isSearchOpen, toggleSearch] = useSearchOpen()
  const menuItemsLength = menuItems.length

  const { subMenuStatus, setSubMenu, onClickMenuItem, onCloseSubMenu } =
    useSubMenu()
  const { subMenuRefs } = useClickOutsideSubMenu(
    menuItemsLength,
    subMenuStatus,
    setSubMenu,
  )

  return (
    <div data-testid="mainMenu">
      {/* DARK BACKGROUND shows up when user click MenuItem to open SubMenu */}
      <FullScreenBackground
        className={mainMenu('BackgroundSubmenu', {
          isSubMenuOpen: subMenuStatus.isSubMenuOpen,
        })}
      />

      <FullScreenBackground
        className={mainMenu('BackgroundMenuItem', { withSearchBar: false })}
      />
      <div className={mainMenu('Content', { isSearchOpen })}>
        <div className={mainMenu('MegaMenuButtonWrapper')}>
          <button
            data-testid="mainMenu-MegaMenuButton"
            onClick={openMegaMenu}
            aria-label="Open MegaMenu"
            className={mainMenu('MegaMenuButton')}
          >
            <Icon icon="Bars" className={mainMenu('MegaMenuIcon')} />
          </button>
        </div>

        <div className={mainMenu('ItemsWrapper', { isOverOffset })} role="menu">
          {menuItems.map((item, index) => {
            if (item.hasSubMenu) {
              return (
                <div
                  key={index}
                  ref={subMenuRefs.current[index]}
                  className={mainMenu('MenuItemWrapper')}
                >
                  <div
                    className={mainMenu('MenuItem', {
                      current: subMenuStatus.currentIndex === index,
                      isSubMenuOpen: subMenuStatus.isSubMenuOpen,
                      isOverOffset,
                    })}
                    role="menuitem"
                    tabIndex={0}
                    onClick={e => {
                      e.stopPropagation()
                      onClickMenuItem(index)
                    }}
                    onKeyDown={e => {
                      e.stopPropagation()
                      onClickMenuItem(index)
                    }}
                    data-testid="openSubMenu"
                    aria-label={`Open Sub menu of ${item.title}`}
                  >
                    <span
                      className={mainMenu('MainTitle', {
                        current: subMenuStatus.currentIndex === index,
                        isSubMenuOpen: subMenuStatus.isSubMenuOpen,
                      })}
                    >
                      {item.title}
                      <Icon
                        icon="ChevronDown"
                        className={mainMenu('SubMenuIcon', {
                          current: subMenuStatus.currentIndex === index,
                          isSubMenuOpen: subMenuStatus.isSubMenuOpen,
                        })}
                      />
                    </span>
                  </div>
                  <div
                    className={mainMenu('SubMenuWrapper', {
                      current: subMenuStatus.currentIndex === index,
                      isSubMenuOpen: subMenuStatus.isSubMenuOpen,
                    })}
                  >
                    <div className={mainMenu('SubMenuContent')}>
                      <SubMenu
                        casualLinks={item.subMenu ?? []}
                        navLinkTitle={item.title}
                        topicTextUrl={item.url}
                        recentEvents={item?.recentContents ?? []}
                        seeMoreLinkTitle={item?.seeAllLink}
                        seeMoreLinkUrl={item?.seeAllLinkUrl}
                        onClose={onCloseSubMenu}
                        className={mainMenu('SubMenu')}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            return (
              <div
                key={index}
                className={mainMenu('TitleWrapper', { isOverOffset })}
              >
                <Link to={item.url ?? ''} className={mainMenu('Title')}>
                  {item.title}
                </Link>
              </div>
            )
          })}
        </div>

        <div className={mainMenu('SearchButtonWrapper')}>
          <button
            data-testid="mainMenu-SearchButton"
            onClick={toggleSearch}
            aria-label="Open Search"
            className={mainMenu('SearchButton')}
          >
            <Icon icon="Search" className={mainMenu('SearchButtonIcon')} />
          </button>
        </div>
      </div>

      <div className={mainMenu('SearchWrapper', { isOpen: isSearchOpen })}>
        <FullScreenBackground
          className={mainMenu('SearchBackground', { withSearchBar: true })}
        />
        <button
          data-testid="mainMenu-SearchCloseButton"
          className={mainMenu('SearchCloseButton')}
          onClick={toggleSearch}
          aria-label="Close Search"
        >
          <Icon icon="Close" className={mainMenu('SearchCloseIcon')} />
        </button>

        {isSearchOpen && (
          <div className={mainMenu('SearchBarWrapper')}>
            <SearchBar
              classNames={{
                input: mainMenu('SearchBarInput'),
                searchBtn: mainMenu('SearchBarSearchButton'),
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export interface SecondaryMenuProps {
  links: SectionLink[]
}

const secondaryMenu = Bem('SecondaryMenu')

export const SecondaryMenu = ({ links }: SecondaryMenuProps) => {
  if (links.length === 0) return null

  return (
    <nav data-testid="SecondaryMenu" className={secondaryMenu()}>
      {links.map((link, index) => {
        return (
          <Link
            key={index}
            to={link?.url ?? ''}
            className={secondaryMenu('Link')}
          >
            {link?.title ?? ''}
          </Link>
        )
      })}
    </nav>
  )
}
