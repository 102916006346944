import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'
import { ContentPreview } from '@scm/ui-module-content-preview'

import { MenuItemLink, SubMenuProps } from './type'

const subMenu = Bem('SubMenu')

const MAX_LINKS_TOTAL = 20
const MAX_LINKS_IN_COLUMN = 10

export const SubMenu = ({
  casualLinks,
  recentEvents,
  className,
  navLinkTitle,
  topicTextUrl,
  seeMoreLinkTitle,
  seeMoreLinkUrl,
  onClose,
}: SubMenuProps) => {
  const displayRecentEvents = recentEvents.slice(0, 2)

  // As we show limited amount of links evenly distributed we need to slice them by a half
  const halfIndex = Math.min(
    Math.ceil(casualLinks.length / 2),
    MAX_LINKS_IN_COLUMN,
  )
  const casualLinks1 = casualLinks.slice(0, halfIndex)
  const casualLinks2 = casualLinks.slice(halfIndex, MAX_LINKS_TOTAL)

  return (
    <div
      data-module="sub-menu"
      className={subMenu({}, [className])}
      role="presentation"
      onClick={onClose}
    >
      <div className={subMenu('Container')}>
        <div className={subMenu('RelatedTopicsWrapper')}>
          <h4 className={subMenu('RelatedTopics')}>Related Topics</h4>
          <div className={subMenu('CasualLinkWrapper')} data-testid="related">
            <CasualLinkList casualLinks={casualLinks1} />
            <CasualLinkList casualLinks={casualLinks2} />
          </div>
        </div>
        <div className={subMenu('RecentContentWrapper')} data-testid="recent">
          {displayRecentEvents.length > 0 ? (
            <>
              <div className={subMenu('RecentContentHeading')}>
                <h4
                  data-testid="recent-title"
                  className={subMenu('RecentText')}
                >
                  Recent in&nbsp;
                  {topicTextUrl ? (
                    <Link
                      to={seeMoreLinkUrl}
                      className={subMenu('RecentTopicTerm')}
                    >
                      {navLinkTitle}
                    </Link>
                  ) : (
                    navLinkTitle
                  )}
                </h4>
                {seeMoreLinkUrl && (
                  <div data-testid="see-more" className={subMenu('SeeMore')}>
                    <Link
                      to={seeMoreLinkUrl}
                      className={subMenu('MoreContent')}
                    >
                      <span className={subMenu('SeeMoreText')}>
                        {seeMoreLinkTitle ?? `See All`}
                      </span>
                      <Icon
                        icon="ChevronRight"
                        className={subMenu('SeeMoreIcon')}
                      />
                    </Link>
                  </div>
                )}
              </div>
              <div className={subMenu('ContentWrapper')}>
                {displayRecentEvents.map((item, index) => (
                  <ContentPreview key={`recent-event-${index}`} {...item} />
                ))}
              </div>
            </>
          ) : (
            <>
              {seeMoreLinkUrl && (
                <div data-testid="see-more" className={subMenu('SeeMore')}>
                  <Link
                    to={seeMoreLinkUrl}
                    className={subMenu('MoreContent', { noRecent: true })}
                  >
                    <span className={subMenu('SeeMoreText')}>
                      {seeMoreLinkTitle ?? `See All`}
                    </span>
                    <Icon
                      icon="ChevronRight"
                      className={subMenu('SeeMoreIcon')}
                    />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

interface CasualLinkListProps {
  casualLinks: MenuItemLink[]
}

const CasualLinkList = ({ casualLinks }: CasualLinkListProps) => {
  if (!casualLinks.length) return null

  return (
    <ul className={subMenu('CasualLinkList')}>
      {casualLinks.map((item, index) => (
        <li key={`casual-link-${index}`} className={subMenu('CasualLinkItem')}>
          <Link
            to={item.url ?? ''}
            className={subMenu('ItemLink')}
            data-testid="sub-menu-item-link"
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
