import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'

export const useSearchOpen = (): [boolean, () => void] => {
  const [isSearchOpen, setSearchOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/search') setSearchOpen(false)
  }, [location.pathname])

  const toggleSearch = () => {
    if (location.pathname !== '/search') setSearchOpen(!isSearchOpen)
  }

  return [isSearchOpen, toggleSearch]
}
