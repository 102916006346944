import { CSSProperties, FC } from 'react'

import { Bem } from '@scm/ui-core'
import { ClassName } from '@scm/ui-tailwind'

export const fullScreenBackground = Bem('FullScreenBackground')

export const FullScreenBackground: FC<{
  className?: ClassName
  style?: CSSProperties
}> = ({ className, style }) => (
  <div style={style} className={fullScreenBackground({}, [className])} />
)
