import { useLocation } from '@remix-run/react'
import { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'

import { Bem } from '@scm/ui-core'

import { FullScreenBackground, MainMenu, SecondaryMenu } from './subComponents'
import { NavigationProps } from './type'

const initialState = {
  isOverOffset: false,
  isMegaOpen: false,
}

export const reducer = (
  state: {
    isOverOffset: boolean
    isMegaOpen: boolean
  },
  action: {
    type: string
  },
) => {
  switch (action.type) {
    case 'scrollingOverOffset':
      return {
        ...state,
        isOverOffset: true,
      }
    case 'scrollingUnderOffset':
      return {
        ...state,
        isOverOffset: false,
      }
    case 'openMegaMenu':
      return {
        ...state,
        isMegaOpen: true,
      }
    case 'closeMegaMenu':
      return {
        ...state,
        isMegaOpen: false,
      }
    default:
      throw new Error()
  }
}

const navigation = Bem('Navigation')

export const Navigation = ({
  header,
  secondaryMenu,
  megaMenu,
  navBase,
  mainMenuItems,
}: NavigationProps) => {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState)
  const navBaseRef = useRef<HTMLDivElement>(null)
  const navWrapperRef = useRef<HTMLDivElement>(null)
  const [heightNavWrapper, setHeightNavWrapper] = useState<number>(0)

  useEffect(() => {
    const handler = () => {
      const offset = navBaseRef.current?.offsetTop
        ? navBaseRef.current?.offsetTop
        : 10
      const bigSkyAdElement = document.getElementById('bigsky_v')
      const totalOffset = (bigSkyAdElement?.clientHeight || 0) + offset
      if (window.pageYOffset > totalOffset) {
        dispatch({ type: 'scrollingOverOffset' })
      } else {
        dispatch({ type: 'scrollingUnderOffset' })
      }
    }
    window.addEventListener('scroll', handler)
  }, [])

  const canUseDOM = typeof window !== 'undefined'
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect
  useIsomorphicLayoutEffect(() => {
    const handleHeightNavWrapper = () => {
      let navHeight = navWrapperRef?.current?.offsetHeight || 200
      if (navHeight > 207) {
        navHeight = 207
      }
      setHeightNavWrapper(navHeight)
    }
    handleHeightNavWrapper()
    window.addEventListener('resize', handleHeightNavWrapper)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navWrapperRef.current])

  useEffect(() => {
    //make body unscrollable while MegaMenu is open
    if (state.isMegaOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'
  }, [state.isMegaOpen])

  const openMegaMenu = () => dispatch({ type: 'openMegaMenu' })
  const closeMegaMenu = () => dispatch({ type: 'closeMegaMenu' })

  // When the navigation changes close the mega menu automatically
  useEffect(closeMegaMenu, [location])

  return (
    <nav
      data-module="navigation"
      aria-label="navigation"
      className={navigation()}
      ref={navWrapperRef}
    >
      {/* MEGA MENU */}
      {megaMenu && state.isMegaOpen && (
        <div className={navigation('MegaMenu')}>{megaMenu(closeMegaMenu)}</div>
      )}

      {!state.isOverOffset && (
        <>
          {header && (
            <div data-testid="header" className={navigation('HeaderWrapper')}>
              <FullScreenBackground
                className={navigation('HeaderBackground')}
              />

              <div className={navigation('Header')}>{header}</div>
            </div>
          )}

          <div className={navigation('SecondaryMenuWrapper')}>
            <FullScreenBackground
              className={navigation('SecondaryMenuBackground')}
            />

            <div className={navigation('SecondaryMenu')}>
              <SecondaryMenu links={secondaryMenu} />
            </div>
          </div>
        </>
      )}

      <div
        style={{
          paddingTop: state.isOverOffset ? `${heightNavWrapper}px` : '0px',
        }}
      />

      {/*
      Becomes sticky onScroll event 
      id="navigation-header" used by contentTable headSticky to calc table's sticky head position under navigation bar 
      */}

      <div
        ref={navBaseRef}
        id="navigation-header"
        className={navigation('NavBase', { isOverOffset: state.isOverOffset })}
      >
        {navBase(openMegaMenu, state.isOverOffset)}

        <div className={navigation('MainMenu')}>
          <MainMenu
            isOverOffset={state.isOverOffset}
            menuItems={mainMenuItems}
            openMegaMenu={openMegaMenu}
          />
        </div>
      </div>
    </nav>
  )
}
