import { ReactNode } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { SearchBar } from '@scm/ui-component-search-bar'
import { Bem } from '@scm/ui-core'

import { useSearchOpen } from '../hooks'
import { Image as ImageType } from '../type'
import { FullScreenBackground } from './fullScreenBackground'

export const navBase = Bem('NavBase')

export interface NavBaseProps {
  logo: ImageType
  openMegaMenu: () => void
  children: ReactNode
  isOverOffset: boolean
}

export const NavBase = ({
  logo,
  openMegaMenu,
  children,
  isOverOffset,
}: NavBaseProps) => {
  const [isSearchOpen, toggleSearch] = useSearchOpen()

  return (
    <div data-testid="navBase" className={navBase()}>
      <div className={navBase('Content', { isOverOffset })}>
        <FullScreenBackground className={navBase('SecondaryMenuBackground')} />
        <div className={navBase('HeaderWrapper')}>
          <div className={navBase('IconsWrapper')}>
            <button
              data-testid="navBase-megaMenuButton"
              className={navBase('MegaMenuButton')}
              onClick={openMegaMenu}
              aria-label="Open Mega menu"
            >
              <Icon
                icon="Bars"
                className={navBase('MegaMenuIcon', {
                  iconVariant: 'small',
                  iconType: 'bars',
                })}
              />
            </button>
            <button
              data-testid="SearchButtonDesktop"
              className={navBase('SearchButtonDesktop')}
              onClick={toggleSearch}
              aria-label="Open Search"
            >
              <Icon
                icon="Search"
                className={navBase('SearchIcon', {
                  iconVariant: 'large',
                  iconType: 'search',
                })}
              />
            </button>
            <button
              data-testid="SearchButtonTablet"
              className={navBase('SearchButtonTablet')}
              onClick={openMegaMenu}
              aria-label="Open Search in MegaMenu"
            >
              <Icon
                icon="Search"
                className={navBase('SearchIcon', {
                  iconVariant: 'small',
                  iconType: 'search',
                })}
              />
            </button>
          </div>
          <div className={navBase('LogoWrapper')}>
            <button
              data-testid="navBase-LogoMegaMenuButton"
              className={navBase('LogoMegaMenuButton')}
              onClick={openMegaMenu}
              aria-label="Open Mega menu"
            >
              <Icon
                icon="Bars"
                className={navBase('LogoMegaMenuIcon', {
                  iconVariant: 'small',
                  iconType: 'bars',
                })}
              />
            </button>
            <Link to="/" className={navBase('Logo')}>
              <Image
                src={logo.url}
                alt={logo.altText}
                className={navBase('LogoImage')}
                data-testid="logo-image"
                fallback="brand"
                width={476}
              />
            </Link>
          </div>
          <div className={navBase('RegisterWrapper')}>
            {children}
            <button
              data-testid="RegisterSearchButton"
              className={navBase('RegisterSearchButton')}
              onClick={openMegaMenu}
              aria-label="Open Search in MegaMenu"
            >
              <Icon
                icon="Search"
                className={navBase('SearchIcon', {
                  iconVariant: 'large',
                  iconType: 'search',
                })}
              />
            </button>
          </div>
        </div>

        <div className={navBase('SearchWrapper', { isOpen: isSearchOpen })}>
          <FullScreenBackground className={navBase('SearchBackground')} />
          <button
            data-testid="SearchCloseButton"
            className={navBase('SearchCloseButton')}
            onClick={toggleSearch}
            aria-label="Close Search"
          >
            <Icon
              icon="Close"
              className={navBase('SearchIcon', {
                iconVariant: 'medium',
                iconType: 'close',
              })}
            />
          </button>

          {isSearchOpen && (
            <div className={navBase('SearchBarWrapper')}>
              <SearchBar />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
